import React from "react"
import { Link, graphql } from "gatsby"
import { Purple, LightPink, LightBlue, Background, DarkGray} from "../styles/constants";
import styled from 'styled-components';
import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const BlogPostHeader = styled.h1`
      margin-top: 5rem;
      margin-bottom: 0;
      font-size: 180%;
      color: ${Purple}
    `;
    const DateHeader = styled.h3`
      margin-top: 0;
      margin-left: 2rem;
      color: ${LightBlue};
    `
    const BlogPost = styled.section`
      display: flex;
      justify-content: center;
      width: 100%;
      @media only screen and (max-width: 450px) {
        font-size: 60%;
      }
    `;
    const BlogPostSection = styled.section`
      font-size: 140%;
      width: 60%;
      @media only screen and (max-width: 750px) {
        margin: 0;
        width: 100%;
        padding: 0 1.5rem 0 1.5rem;
      }
    `;
    
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle} style={{ margin: "2rem" }}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          keywords = {post.frontmatter.tag || []} 
        />
        <BlogPost>
        <BlogPostSection>
        <BlogPostHeader>{post.frontmatter.title}</BlogPostHeader>
        <DateHeader>{post.frontmatter.date}</DateHeader>
        <div dangerouslySetInnerHTML={{ __html: post.html }} className="blog-post"/>
        <hr
          style={{
          }}
        />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        </BlogPostSection>
        </BlogPost>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tag
      }
    }
  }
`
